import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()
const farms: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'FAY',
    lpAddresses: {
      2153: '0x5596b3A7554e1a4581b7B12da153Bb1aDda2Fa4d',
      2152: '0xaf196C2d7870B5146F02D49b011dF9197C093Ffe',
    },
    token: serializedTokens.fay,
    quoteToken: serializedTokens.fay,
  },
  {
    pid: 1,
    lpSymbol: 'FRA-FAY',
    lpAddresses: {
      2153: '0xaBF9eAB45E256C26D136f60b82485990Ad02ADf0',
      2152: '0x67C378dB6BaA89812B832B8CC37A1A03c00a39B0',
    },
    token: serializedTokens.fay,
    quoteToken: serializedTokens.fra,
  },
  {
    pid: 2,
    lpSymbol: 'FRA-WETH.b',
    lpAddresses: {
      2153: '0x92b42145B1ECCA36dB26b156a91D35e24DC34428',
      2152: '0xAcCCf602E9A626f3A5A03057aE1121EbdBde0651',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.fra,
  },
  {
    pid: 3,
    lpSymbol: 'FRA-USDT.e',
    lpAddresses: {
      2153: '0x38b784cB59c3C3EF8F5aD240E2253c02A0d5f255',
      2152: '0x0186Db02668c504Bd23FeE2f046346b4257ca91b',
    },
    token: serializedTokens.usdt_e,
    quoteToken: serializedTokens.fra,
  },
  {
    pid: 4,
    lpSymbol: 'FRA-BUSD.b',
    lpAddresses: {
      2153: '0x70d24a03eF418e9e52d7C49B3857BCdDA61fDC97',
      2152: '0x5Bcc639f51aD48D0B27084cB054211De324A4A9e',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.fra,
  },
  {
    pid: 5,
    lpSymbol: 'FRA-WBNB.b',
    lpAddresses: {
      2153: '0x057204083A720ACd0b7Dbe0b2c2648f9b45C807D',
      2152: '0x74Cd034330527579DE906dEE4Fba60d9e05B1B12',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.fra,
  },
  {
    pid: 6,
    lpSymbol: 'FRA-USDC.e',
    lpAddresses: {
      2153: '0xf1d28742Cff940873C1C9812C39b8a061Cf5A687',
      2152: '0x787cc97853f242eFDAe973D04A4c79c58E2155B0',
    },
    token: serializedTokens.usdc_e,
    quoteToken: serializedTokens.fra,
  },
  {
    pid: 7,
    lpSymbol: 'FRA-USDT.b',
    lpAddresses: {
      2153: '0xb6B378262E9B54A312EB438BD3ff2EeF7e6CB1c8',
      2152: '0x71f4AAf689F5E4E0c98AD8a55000873eB0a614A7',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.fra,
  },
  {
    pid: 8,
    lpSymbol: 'FRA-WETH.e',
    lpAddresses: {
      2153: '0xA5539BFf0c05E0719D458857c1b82556bCEc836C',
      2152: '0x5976D52d6Ed740F190a2B82a5363786f26D990E9',
    },
    token: serializedTokens.eth_e,
    quoteToken: serializedTokens.fra,
  },
]

export default farms