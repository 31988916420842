import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { getLanguageCodeFromLS } from 'contexts/Localization/helpers'
import numbro from 'numbro'
import { BIG_TEN } from './bigNumber'

export const formatDecimal = (number: number, decimal = 2): string => {
  if (number === undefined) return ''
  let num = number.toString()
  const index = num.indexOf('.')
  if (index !== -1) {
      num = num.substring(0, decimal + index + 1)
  } else {
      num = num.substring(0)
  }
  return parseFloat(num).toFixed(decimal)
}

export const formatMoney = (value: number, n = 2): string => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(value))) return Number(0).toFixed(n > 0 ? n : 0)
  if(value === 0) return '0.00'
  const isNegative = value < 0
  const v = formatDecimal(Math.abs(Number(value)), n > 0 ? n : 0)
  const l = v.split('.')[0].split('').reverse()
  const r = v.split('.')[1]
  let t = ''
  for (let i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
  }
  // eslint-disable-next-line prefer-template
  const res = t.split('').reverse().join('') + `${r && r !== '00' ? '.' + r : ''}`
  return `${isNegative ? '-' : ''}${res}`
}

// using a currency library here in case we want to add more in future
export const formatDollarAmount = (num: number | string | undefined, digits = 2, round = true): string => {
    if (Number(num) === 0) return '$0.00'
    if (!num) return '-'
    if (num < 0.001 && digits <= 3) {
        return '<$0.001'
    }

    return numbro(num).formatCurrency({
        average: round,
        mantissa: num > 1000 ? 2 : digits,
        abbreviations: {
            million: 'M',
            billion: 'B'
        }
    })
}

// using a currency library here in case we want to add more in future
export const formatAmount = (num: number | undefined, digits = 2): string => {
    if (num === 0) return '0'
    if (!num) return '-'
    if (num < 0.001) {
        return '<0.001'
    }
    if(num < 1000) return `${num}`
    return numbro(num).format({
        average: true,
        mantissa: num > 1000 ? 2 : digits,
        abbreviations: {
            million: 'M',
            billion: 'B'
        }
    }).toUpperCase()
}

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  return getBalanceAmount(balance, decimals).toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, displayDecimals?: number) => {
  return getBalanceAmount(balance, decimals).toFixed(displayDecimals)
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString(undefined, options)
}

/**
 * Method to format the display of wei given an ethers.BigNumber object
 * Note: does NOT round
 */
export const formatBigNumber = (number: ethers.BigNumber, displayDecimals = 18, decimals = 18) => {
  const remainder = number.mod(ethers.BigNumber.from(10).pow(decimals - displayDecimals))
  return formatUnits(number.sub(remainder), decimals)
}

/**
 * Method to format the display of wei given an ethers.BigNumber object with toFixed
 * Note: rounds
 */
export const formatBigNumberToFixed = (number: ethers.BigNumber, displayDecimals = 18, decimals = 18) => {
  const formattedString = formatUnits(number, decimals)
  return (+formattedString).toFixed(displayDecimals)
}

/**
 * Formats a FixedNumber like BigNumber
 * i.e. Formats 9763410526137450427.1196 into 9.763 (3 display decimals)
 */
export const formatFixedNumber = (number: ethers.FixedNumber, displayDecimals = 18, decimals = 18) => {
  // Remove decimal
  const [leftSide] = number.toString().split('.')
  return formatBigNumber(ethers.BigNumber.from(leftSide), displayDecimals, decimals)
}

export const formatLocalisedCompactNumber = (number: number): string => {
  const codeFromStorage = getLanguageCodeFromLS()
  return new Intl.NumberFormat(codeFromStorage, {
    notation: 'compact',
    compactDisplay: 'long',
    maximumSignificantDigits: 2,
  }).format(number)
}

export default formatLocalisedCompactNumber
