import { ChainId, Token } from '@fairy-swap-v2/sdk'
import CONST from 'app.constants'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  fra: new Token(MAINNET, CONST.addr.stakedToken, 18, 'FRA', 'FRA', 'https://www.findora.org/'),
  fairy: new Token(MAINNET, '0xcCC94d78b01D94330F25f7B8e827ef24249132DE', 18, 'FAIRY', 'FAIRY', 'https://www.findora.org/'),
  fay: new Token(MAINNET, '0xaf196C2d7870B5146F02D49b011dF9197C093Ffe', 18, 'FAY', 'FAY', 'https://www.findora.org/'),
  busd: new Token(MAINNET, '0xE80EB4a234f718eDc5B76Bb442653827D20Ebb2d', 18, 'BUSD.b', 'BUSD.b', 'https://www.findora.org'),
  usdc: new Token(MAINNET, '0xdA33eF1A7b48beBbF579eE86DFA735a9529C4950', 18, 'USDC.b', 'USDC.b', 'https://www.findora.org'),
  usdt: new Token(MAINNET, '0x93EDFa31D7ac69999E964DAC9c25Cd6402c75DB3', 18, 'USDT.b', 'USDT.b', 'https://www.findora.org'),
  wbnb: new Token(MAINNET, '0xABc979788c7089B516B8F2f1b5cEaBd2E27Fd78b', 18, 'WBNB.b', 'WBNB.b', 'https://www.findora.org'),
  eth: new Token(MAINNET, '0x008A628826E9470337e0Cd9c0C944143A83F32f3', 18, 'ETH.b', 'ETH.b', 'https://www.findora.org'),
  btcb: new Token(MAINNET, '0x07EfA82E00E458ca3D53f2CD5B162e520F46d911', 18, 'BTC.b', 'BTC.b', 'https://www.findora.org'),
  fld: new Token(MAINNET, '0x020cF6c12B08AcbE78544C4F3319A749b0601780', 18, 'FLD', 'FLD', 'https://www.findora.org'),

  eth_e: new Token(MAINNET, '0xaFfAac009Af35d6069E79Ef3763A39A2BA5BF65f', 18, 'ETH.e', 'ETH.e', 'https://www.findora.org'),
  usdt_e: new Token(MAINNET, '0x0632baa26299C9972eD4D9AfFa3FD057A72252Ff', 6, 'USDT.e', 'USDT.e', 'https://www.findora.org'),
  usdc_e: new Token(MAINNET, '0x2e8079E0fE49626AF8716fC38aDEa6799065D7f7', 6, 'USDC.e', 'USDC.e', 'https://www.findora.org'),
  wbtc_e: new Token(MAINNET, '0x38f9dA0D8A84Ad841281080Ad4a2D9D89Eff3bFf', 8, 'WBTC.e', 'WBTC.e', 'https://www.findora.org'),
}

export const testnetTokens = {
  fra: new Token(TESTNET, CONST.addr.stakedToken, 18, 'FRA', 'FRA', 'https://www.findora.org/'),
  fay: new Token(TESTNET, '0x5596b3A7554e1a4581b7B12da153Bb1aDda2Fa4d', 18, 'FAY', 'FAY', 'https://www.findora.org/'),
  fairy: new Token(TESTNET, '0x0AE8D5F0620C61BF8E160A80C5a99e7E2CA66377', 18, 'FAIRY', 'FAIRY', 'https://www.findora.org/'),
  fld: new Token(TESTNET, '0xC5939cd85bcBeaEDB4fa3256BA39fB7B8a3D6bA6', 18, 'FLD', 'FLD', 'https://www.findora.org'),
  usdt: new Token(TESTNET, '0x801d60Be44167081A804F7d275a7850915537CfC', 18, 'USDT', 'USDT', 'https://www.findora.org'),
  busd: new Token(TESTNET, '0x5f5c4436f7fbe578bC10C34ab6161cbe8a711014', 18, 'BUSD', 'BUSD', 'https://www.findora.org'),
  usdc: new Token(TESTNET, '0x26d10027557F9F8bB0bd1a9ea18830f3C2634CBC', 18, 'USDC.b', 'USDC.b', 'https://www.findora.org'),
  wbnb: new Token(TESTNET, '0x99Cd65f71550DCfAed6267F92Af7378D475f7858', 18, 'WBNB.b', 'WBNB.b', 'https://www.findora.org'),
  eth: new Token(TESTNET, '0xF183aaf38bD7DF299f22880D2c4f2dc83Be70362', 18, 'ETH.b', 'ETH.b', 'https://www.findora.org'),
  btcb: new Token(TESTNET, '0x986F2cCb74F5037020c49e5597a50B95D5C1af61', 18, 'BTC.b', 'BTC.b', 'https://www.findora.org'),

  eth_e: new Token(TESTNET, '0xF968672B5A7c1F80A094B8EE595Be4a6F2CFd78b', 18, 'ETH.e', 'ETH.e', 'https://www.findora.org'),
  usdt_e: new Token(TESTNET, '0x25066acaD2083ff06eE071d563a39D9a93eAd200', 18, 'USDT.e', 'USDT.e', 'https://www.findora.org'),
  usdc_e: new Token(TESTNET, '0x6092620799811A60bF26Fb2E53372c46dDCA0298', 18, 'USDC.e', 'USDC.e', 'https://www.findora.org'),
  wbtc_e: new Token(TESTNET, '0x80EeD2AF16840325c177c914Af33Aaa092294De7', 18, 'WBTC.e', 'WBTC.e', 'https://www.findora.org'),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
