import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from 'fairyswap-uikit'
import { useTranslation } from 'contexts/Localization'

const StyledNav = styled.nav`
  margin-bottom: 42px;
`

const Tab = styled(ButtonMenu)`
  width: 240px;
  background: rgba(0, 0, 0, 0.16);
  text-align: center;
  border-radius: 12px;
  border: 0 none;
`

const TabItem = styled(ButtonMenuItem)`
  color: rgba(255, 255, 255, .6);
  height: 46px;
  line-height: 46px;
  width: 120px;
  font-size: 16px;
`
const ActiveItem = styled(TabItem)`
  color: #fff; 
  background: #723CB5;
  border-radius: 12px;
  font-weight: bold;
`

const getActiveIndex = (pathname: string): number => {
  if (
    pathname.includes('/pool') ||
    pathname.includes('/create') ||
    pathname.includes('/add') ||
    pathname.includes('/remove') ||
    pathname.includes('/find') ||
    pathname.includes('/liquidity')
  ) {
    return 1
  }
  return 0
}

const Nav = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const Swap = getActiveIndex(location.pathname) === 0 ? ActiveItem : TabItem
  const Pool = getActiveIndex(location.pathname) === 1 ? ActiveItem : TabItem
  return (
    <StyledNav>
      <Tab activeIndex={getActiveIndex(location.pathname)}>
        <Swap id="swap-nav-link" to="/swap" as={Link}>
          {t('Swap')}
        </Swap>
        <Pool id="pool-nav-link" to="/pool" as={Link}>
          {t('Liquidity')}
        </Pool>
      </Tab>
    </StyledNav>
  )
}

export default Nav
