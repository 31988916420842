import React from 'react'
import styled, { css } from 'styled-components'
import {
  TokenImage as UIKitTokenImage,
  ImageProps
} from 'fairyswap-uikit'
import tokens from 'config/constants/tokens'
import { Token } from '@fairy-swap-v2/sdk'

const getImageUrlFromToken = (token: Token) => {
  const address = token.symbol === 'FRA' ? tokens.fra.address : token.address
  return `/images/tokens/${address}.png`
}

const StylePairImages = styled.div`
  position: relative;
  width: 67px;
  height: 59px;
`

const StylePrimaryImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
`

const StyleSecondaryImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 34px;
`

export const TokenPairImage: React.FC<{primaryToken: Token, secondaryToken: Token}> = ({ primaryToken, secondaryToken }) => {
  return (
    <StylePairImages>
      <StyleSecondaryImage src={getImageUrlFromToken(secondaryToken)}  />
      <StylePrimaryImage src={getImageUrlFromToken(primaryToken)}  />
    </StylePairImages>
  )
}

interface TokenImageProps extends ImageProps {
  token: Token
}

export const TokenImage: React.FC<TokenImageProps> = ({ token, ...props }) => {
  return <UIKitTokenImage src={getImageUrlFromToken(token)} {...props} />
}
