import BigNumber from "bignumber.js";

const appIsProd = process.env.REACT_APP_CHAIN_ID === '2152'

// Default app constant values
class AppConst {

  isProd = true;

  chainId = Number(process.env.REACT_APP_CHAIN_ID);

  projectLink = 'https://www.findora.org'

  mailSub = {
    type: 'mailchimp',
    base: 'https://<SUBDOMAIN>.list-manage.com/subscribe/post?u=<U>&id=<ID>',
    subdomain: 'finance.us20',
    u: '651b60a305ffb434bbabda60c',
    id: 'ba5f8473aa',
  };

  network = {
    rpcUrl: appIsProd ? 'https://prod-mainnet.prod.findora.org:8545' : 'https://prod-testnet.prod.findora.org:8545',
    explorerUrl: appIsProd ? 'https://evm.findorascan.io/' : 'https://prod-mainnet01-blockscout.prod.findora.org/'
  };


  addr = {
    specialStaking: appIsProd ? '0x0575Bc2Cf301b8c11A4029c5Ce3269F75A16eDDC' : '0xfd248075190aaB00FBe0dCD25565B5023F64E2e4', // SmartChef address
    fairy: appIsProd ? '0xcCC94d78b01D94330F25f7B8e827ef24249132DE' : '0x1aC38B47f74D1D4be7e513149d4EaC49b629c843', // FAIRY erc20 address
    stakedToken: '0x0000000000000000000000000000000000001000', // wrapped FRA address
  };

  fairyPrice = new BigNumber('0.000001');

  fraPrice = new BigNumber('0.01816');

  specialStaking = {
    pid: 999123456789,
  }

  getFraUrl = 'https://www.gate.ac/cn/trade/FRA_USDT';

  blockRewardString = '20,000 Fairy/Day';
  
  genesisDate = new Date(`2022-04-13T00:00:00.000Z`);

  currentTvl = '$2.4 Million';

  constructor(init?: Partial<AppConst>) {
    if (init) { Object.assign(this, init); }
  }

  get mailSubscribeUrl() {
    const { subdomain, u, id, base } = this.mailSub;
    return base.replace('<SUBDOMAIN>', subdomain).replace('<U>', u).replace('<ID>', id);
  }

}

const CONST = appIsProd ?
  new AppConst({
    isProd: true,
  })
  : 
  new AppConst({
    isProd: false,
  });


export default CONST;
