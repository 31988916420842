import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import CONST from "app.constants"
import BigNumber from "bignumber.js";
import { ethers } from "ethers"
import { GenericToken__factory, SmartChef__factory } from "types"
import { getProviderInfo } from "./util";

export interface SpecialStakingData {
  noProvider: boolean;
  loading: boolean;
  pendingFairy: string;
  stakedAmount: string;
  rewardDebt: string;
  rewardsPerSecond: string;
  rewardsPerDay: string;
  stakableTokenAllowance: string;
  stakableTokenBalance: string;
  lastFetched: number;
}

export const initialState: SpecialStakingData = {
  noProvider: false,
  loading: true,
  pendingFairy: new BigNumber(0).toString(),
  stakedAmount: new BigNumber(0).toString(),
  rewardDebt: new BigNumber(0).toString(),
  rewardsPerSecond: new BigNumber(0).toString(),
  rewardsPerDay: new BigNumber(0).toString(),
  stakableTokenAllowance: new BigNumber(0).toString(),
  stakableTokenBalance: new BigNumber(0).toString(),
  lastFetched: 0,
}

// Avoid multiple fetches 
const asyncFetchControl = {
  fetchLast: 0,
  fetchTtl: 15 * 1000, // keep cache for 15s, unless forced
}

async function fetchSpecialStakingData(force = false) {
  if (!force && Date.now() - asyncFetchControl.fetchLast < asyncFetchControl.fetchTtl) { return null; }
  const [ provider, signer, account ] = await getProviderInfo();
  if (!signer || !account) { return null; }
  asyncFetchControl.fetchLast = Date.now();
  const contract = new SmartChef__factory().connect(provider.getSigner());
  const stakedTokenContract = new GenericToken__factory().connect(provider.getSigner());
  const contractInst = contract.attach(CONST.addr.specialStaking);
  const stakedTokenContractInst = stakedTokenContract.attach(CONST.addr.stakedToken);
  const userAddr = account;
  const pendingFairy = await contractInst.pendingFairy(userAddr);
  const rewardsPerSecond = await contractInst.rewardsPerSecond();
  const info = await contractInst.userInfo(userAddr);
  const stakedAmount = info.amount;
  const rewardDebt = info.rewardDebt;
  const stakableTokenAllowance = await stakedTokenContractInst.allowance(account, CONST.addr.specialStaking);
  const stakableTokenBalance = await stakedTokenContractInst.balanceOf(account);
  const rewardsPerDay = Math.round(parseFloat(new BigNumber(rewardsPerSecond.toString()).multipliedBy('8640000').dividedBy('1e18').toString())) / 100;
  const data: SpecialStakingData = {
    noProvider: false,
    loading: false,
    pendingFairy: pendingFairy.toString(),
    stakedAmount: stakedAmount.toString(),
    rewardDebt: rewardDebt.toString(),
    rewardsPerSecond: rewardsPerSecond.toString(),
    rewardsPerDay: rewardsPerDay.toString(),
    stakableTokenAllowance: stakableTokenAllowance.toString(),
    stakableTokenBalance: stakableTokenBalance.toString(),
    lastFetched: Date.now(),
  };
  return data;
}

const fetchSpecialStakingDataAsyncActionName = 'specialStaking/fetchSpecialStakingDataAsync'
export const fetchSpecialStakingDataAsyncAction = createAction<SpecialStakingData>(fetchSpecialStakingDataAsyncActionName)
export const fetchSpecialStakingDataAsync = createAsyncThunk<SpecialStakingData, boolean>(
  fetchSpecialStakingDataAsyncActionName,
  async (force = false) => {
    return fetchSpecialStakingData(force);
  },
)

export const specialStakingSlice = createSlice({
  name: 'specialFarms',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpecialStakingDataAsync.fulfilled, (state, action) => {
      if (!action.payload) {
        return state;
      }
      state.noProvider = action.payload.noProvider;
      state.loading = action.payload.loading;
      state.pendingFairy = action.payload.pendingFairy;
      state.rewardDebt = action.payload.rewardDebt;
      state.rewardsPerSecond = action.payload.rewardsPerSecond;
      state.rewardsPerDay = action.payload.rewardsPerDay;
      state.stakedAmount = action.payload.stakedAmount;
      state.stakableTokenAllowance = action.payload.stakableTokenAllowance;
      state.stakableTokenBalance = action.payload.stakableTokenBalance;
      state.lastFetched = action.payload.lastFetched;
      return state;
    })
  }
})

export default specialStakingSlice.reducer;
