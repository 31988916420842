import { ChainId } from '@fairy-swap-v2/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { differenceInCalendarDays } from 'date-fns'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getOracleAddress = () => {
  return getAddress(addresses.Oracle)
}
export const getIDOAddress = () => {
  // const arr = [
  //   "0x613bc00abfc43CddE886F71B302A14aba3aaa077",
  //   "0x6DEC990A7Cb999042Abb9c63c9985F167F2Ad33B",
  //   "0xda4D6c568b9784afd10765E14909D925a206691b",
  //   "0x8691705e90042637452160F7FCcC50BD5FC07AEb",
  //   "0x817F17C5DAd4458c576cFf61ace8c8F2F72Ece7A",
  //   "0xAdb29EE01e792ffABfbcC8448B3Ba2E546448353",
  //   "0x3993080743e1Ae8Bf6a5049dEBe99AC624dae40e",
  //   "0xfDAD01Fc2d47ba028Df9A102CB7f528Ef212608d",
  //   "0x2BBf63Cbd13022Dc9f2B4DaD46961E9436516E11",
  //   "0x826A6D8C9FC27a3713A27f31eeA5E978ff5b95C5"
  // ]
  // const now = new Date()
  // const start = new Date(1650556800000)
  // const diff = differenceInCalendarDays(now, start)
  // let index = 0
  // if(diff > 0 && diff <10){
  //   index = diff
  // }
  
  // return arr[index]
  return getAddress(addresses.IDO)
}
export const getTradeInAddress = () => {
  return getAddress(addresses.TradeIn)
}
export const getAirDropAddress = () => {
  return getAddress(addresses.AirDrop)
}
export const getFNSAddress = () => {
  return getAddress(addresses.FNS)
}