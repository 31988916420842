import React  from 'react'
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
  ModalBody,
  Button,
  Heading,
} from 'fairyswap-uikit'
import styled from 'styled-components'
import theme2 from 'style/theme2'
import { useTranslation } from 'contexts/Localization'


const StyledModalContainer = styled(ModalContainer)`
  max-width: 440px;
  width: 100%;
  border: 1px solid rgba(140,140,140,0.3);
  .warning-container {
    display:flex;
    padding: 20px;
    padding-top: 16px;
    padding-left: 16px;
    height: 104px;
    background: rgba(220, 0, 88, 0.1);
    border: 1px solid #723CB5;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 10px;
    .warning-ico {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      margin-right: 12px;
    }
    .warning-message {
      flex: 1;
      display: inline-block;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      vertical-align: top;
      color: #fff;
    }
  }
  .dislaimer-content {
    .disclaimer-text {
      padding-bottom: 32px;
      max-height: calc(100vh - 310px);
      overflow-y: auto;
      overflow-x: hidden;
      white-space: pre-line;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #fff;
    }
    .accept-button {
      width: 100%;
      min-height: 50px;
      display: block;
    }
  }
`

const StyleModalHeader = styled(ModalHeader)`
  border: 0 none;
`

const StyledModalBody = styled(ModalBody)`
  padding: 0 24px 24px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyleButton = styled(Button)`
  box-shadow: none;
`


export default function LegalDisclaimerModal({
  onDismiss = () => null,
  closeFunction = () => null,
}) {
  const { t } = useTranslation()
  const head = `Legal Disclaimer`;
  const warning = `This project is a tech demo in beta. Use at your own risk. Not open to US and China persons.`;
  const disclaimer = [
    `You understand and expressly accept that the beta version of FAIRYSWAP is provided to you at your own risk on an “AS IS” and “UNDER DEVELOPMENT” basis.`,
    `THE DEVELOPERS OF FAIRYSWAP MAKE NO WARRANTY WHATSOEVER WITH RESPECT TO THE BETA DEMO, INCLUDING ANY (A) WARRANTY OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; (C) WARRANTY OF TITLE; OR (D) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.`,
    `Nationals and residents of the following countries are restricted from participation: Afghanistan, Cuba, Democratic Republic of the Congo, Guinea-Bissau, Iran, Iraq, Lebanon, Libya, Myanmar, North Korea, Somalia, Sudan, Syria, Yemen, Zimbabwe, and the Crimea region of Ukraine.`,
    `The project is operated by FairySwap Community around the globe outside the United States of America.`].join('\n\n')
  const accept = 'Accept & Continue';
  return (
    <StyledModalContainer minWidth="320px">
      <StyleModalHeader>
        <ModalTitle>
          <Heading>{t(head)}</Heading>
        </ModalTitle>
        <div style={{ transform: 'translate(16px, 0)' }}>
          <ModalCloseButton onDismiss={onDismiss}/>
        </div>
      </StyleModalHeader>
      <StyledModalBody>
        <div className="warning-container">
          <img className="warning-ico" src="images/warning.png" alt="warning"/>
          <div className="warning-message">{t(warning)}</div>
        </div>
        <div className="dislaimer-content">
          <div className="disclaimer-text">{t(disclaimer)}</div>
          <StyleButton className="accept-button" onClick={closeFunction}>{t(accept)}</StyleButton>
        </div>
      </StyledModalBody>
    </StyledModalContainer>
  )
}
