import React, { useEffect, useState, useRef } from 'react'
import { useMatchBreakpoints } from 'fairyswap-uikit'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router'
// import throttle from "lodash/throttle";
import Dropdown from './Dropdown'

// eslint-disable-next-line import/no-unresolved
import UserMenu from './UserMenu'
import GlobalSettings from './GlobalSettings'
import Logo from './img/logo.svg'
import Msg from './img/msg.svg'
import Close from './img/close.svg'
import LogoMobile from './img/mobileLogo.svg'
import MenuIcon from './img/menu.svg'


const Layout = styled.div``
const Header = styled.div<{ isTop?: boolean }>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  /* ${({ isTop }) => (!isTop ? {
    backgroundColor: '#111626',
    backgroundImage: 'url(images/bg.svg)',
    backgroundSize: '100vw auto',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0'
  } : '')}; */
  background-color: #111626;
  background-image: url(images/bg.svg);
  background-size: 100vw auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 0 0;
`
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const HeaderLogo = styled(Link)`
  margin: 0 132px 0 0;
`
const HeaderNav = styled.div`
  display: flex;
  align-items: center;
`
const HeaderNavItem = styled(Link) <{ cur?: boolean }>`
  height: 92px;
  display: flex;
  align-items: center;
  color: ${({ cur }) => (cur ? `#fff` : `rgba(255,255,255, .6)`)};
  margin: 0 40px 0 0;
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  &:hover{
    color: #fff;
  }
  &:after{
    display: ${({ cur }) => (cur ? `block` : `none`)};
    content: ' ';
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    background: #2BCE6D;
    border-radius: 2px;
  }
`

const HeaderNavItemBox = styled.div`
  height: 92px;
  display: flex;
  align-items: center;
  color: rgba(255,255,255, .6);
  margin: 0 40px 0 0;
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  &:hover{
    color: #fff;
  }
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const HeaderMsg = styled.div`
  position: absolute;
  top: 92px;
  left: 0;
  width: 100%;
  background: #723CB5;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span, em{
    display: flex;
    align-items: center;
  }
  span{
    img{
      margin: 0 10px 0 0;
    }
  }
`
const StyleEM = styled.em`
  cursor: pointer;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 120px 40px 40px;
  ${({ theme }) => theme.devicesQueries.mobile} {
    display: block;
    padding: 40px 20px
  }
`

const FooterLeft = styled.div`
color: rgba(255, 255, 255, 0.3);
`

const FooterRight = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.devicesQueries.mobile} {
    padding: 20px 0 0;
  }
  img{
    width: 20px;
    
  }
  a{
    margin: 0 0 0 20px;
    &:first-child{
      margin: 0
    }
  }
`

// mobile
const MobileHeader = styled.div<{ showMenu?: boolean }>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #111626;
  ${({ showMenu }) => !showMenu &&
    css`
      background-image: url(images/bg2.svg);
      background-size: 100vw auto;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position:  0 -20vh;
    `
  }
`
const MobileLogo = styled(Link)``
const MobileNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MobileMenu = styled.img`
  margin: 0 0 0 18px;
`
const MobileMenuBox = styled.div<{ showMenu?: boolean }>`
  position: fixed;
  top: 51px;
  left: 0;
  z-index: 12;
  width: 100%;
  height: calc(100% - 51px);
  background-color: #111626;
  display: ${({ showMenu }) => (showMenu ? `block` : `none`)};
  transition: all 0.3s ease;
`

const MobileNavItemTitle = styled.div`
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
  height: 60px;
  line-height:60px;
  padding: 0 20px;
  display: block;
  color: #fff;
`

const MobileNavItemBox = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : `none`)};
`

const MobileNavItemBoxLink = styled.a`
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  display: block;
  background: #0A0D16;
  color: #fff;
`

const MobileNavItem = styled(Link) <{ cur?: boolean }>`
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.05);
  height: 60px;
  line-height:60px;
  padding: 0 20px;
  display: block;
  color: ${({ cur }) => (cur ? `#2BCE6D` : `#fff`)};
`

const getActive = (pathname: string): string => {
  const arr = pathname.split('/')
  if (
    arr[1] === 'pool' ||
    arr[1] === 'create' ||
    arr[1] === 'add' ||
    arr[1] === 'remove' ||
    arr[1] === 'find' ||
    arr[1] === 'liquidity'
  ) {
    return '/swap'
  } if (arr[1] === 'info') {
    return '/info'
  }
  return pathname
}

const about = [
  {
    label: 'Github',
    href: 'https://github.com/Fairyswap',
  },
  {
    label: 'Docs',
    href: 'https://fairy-swap.gitbook.io/fairyswap-v2/',
  },
  {
    label: 'Litepaper',
    href: 'https://medium.com/@hello.fairy.swap',
  },
  {
    label: 'Audit',
    href: 'https://www.certik.com/projects/fairyswap',
  },
]

const social = [
  {
    name: 'twitter', 
    url: 'https://twitter.com/fairy_swap',
  },
  {
    name: 'telegram', 
    url: 'https://t.me/fairyswap',
  },
  {
    name: 'medium', 
    url: 'https://medium.com/@hello.fairy.swap',
  },
  {
    name: 'discord', 
    url: 'https://discord.gg/wUT7DmRRs4',
  },
  {
    name: 'github', 
    url: 'https://github.com/Fairyswap',
  },
];

const Menu = (props) => {
  const { children } = props
  const { pathname } = useLocation()
  const [showMsg, setShowMsg] = useState(true);
  const [isTop, setIsTop] = useState(true);
  // const refPrevOffset = useRef(window.pageYOffset);
  const { isMobile } = useMatchBreakpoints()
  const [show, setShow] = useState(false)

  const [showMenu, setShowMenu] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentOffset = window.pageYOffset;
  //     const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight;
  //     const isTopOfPage = currentOffset === 0;
  //     if (isTopOfPage) {
  //       setIsTop(true)
  //     } else if (!isBottomOfPage) {
  //       setIsTop(false)
  //     }
  //     refPrevOffset.current = currentOffset;
  //   };
  //   const throttledHandleScroll = throttle(handleScroll, 200);
  //   window.addEventListener("scroll", throttledHandleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", throttledHandleScroll);
  //   };
  // }, []);

  return (
    <Layout>
      {
        isMobile ?
          <>
            <MobileHeader showMenu={showMenu}>
              <MobileLogo to="/"><img src={LogoMobile} alt="" /></MobileLogo>
              <MobileNav>
                <GlobalSettings />
                <UserMenu />
                <MobileMenu src={MenuIcon} alt="" onClick={() => setShowMenu(!showMenu)} />
              </MobileNav>
            </MobileHeader>
            <MobileMenuBox showMenu={showMenu}>
              <MobileNavItem cur={getActive(pathname) === '/swap'} to="/swap" onClick={() => setShowMenu(false)}>Swap</MobileNavItem>
              <MobileNavItem cur={getActive(pathname) === '/farms'} to="/farms" onClick={() => setShowMenu(false)}>Farm</MobileNavItem>
              <MobileNavItem cur={getActive(pathname) === '/fay'} to="/fay" onClick={() => setShowMenu(false)}>FAY Token</MobileNavItem>
              <MobileNavItem as="a" href="https://www.rialtobridge.io/" target="_blank" rel="noreferrer">Bridge</MobileNavItem>
              <MobileNavItem cur={getActive(pathname) === '/info'} to="/info" onClick={() => setShowMenu(false)}>Info</MobileNavItem>
              <MobileNavItemTitle onClick={() => setShow(!show)}>About</MobileNavItemTitle>
              <MobileNavItemBox show={show}>
                {
                  about.map((item) => <MobileNavItemBoxLink key={item.href} href={item.href} target="_blank" rel="noreferrer">{item.label}</MobileNavItemBoxLink>)
                }
              </MobileNavItemBox>
              <MobileNavItem as="a" href="https://v1.fairyswap.finance/" target="_blank" rel="noreferrer">V1</MobileNavItem>
            </MobileMenuBox>
          </>
          :
          <Header isTop={isTop}>
            <HeaderLeft>
              <HeaderLogo to="/"><img src={Logo} alt="" /></HeaderLogo>
              <HeaderNav>
                <HeaderNavItem cur={getActive(pathname) === '/swap'} to="/swap">Swap</HeaderNavItem>
                <HeaderNavItem cur={getActive(pathname) === '/farms'} to="/farms">Farm</HeaderNavItem>
                <HeaderNavItem cur={getActive(pathname) === '/fay'} to="/fay">FAY Token</HeaderNavItem>
                <HeaderNavItem as="a" href="https://www.rialtobridge.io/" target="_blank" rel="noreferrer">Bridge</HeaderNavItem>
                <HeaderNavItem cur={getActive(pathname) === '/info'} to="/info">Info</HeaderNavItem>
                <Dropdown>
                  {
                    about.map((item) => <a key={item.href} href={item.href} target="_blank" rel="noreferrer">{item.label}</a>)
                  }
                </Dropdown>
                <HeaderNavItem as="a" href="https://v1.fairyswap.finance/" target="_blank" rel="noreferrer">V1</HeaderNavItem>
              </HeaderNav>
            </HeaderLeft>
            <HeaderRight>
              <UserMenu />
            </HeaderRight>
            {
              showMsg && !isMobile ?
                <HeaderMsg>
                  <em />
                  <span><img src={Msg} alt="" />FairySwap 1.0 has been fully upgraded to 2.0, and the platform’s currency FAIRY has been renamed to FAY. Please make the necessary exchanges in a timely manner.</span>
                  <StyleEM onClick={() => setShowMsg(false)}><img src={Close} alt="" /></StyleEM>
                </HeaderMsg>
                : null
            }
          </Header>
      }
      {children}
      <Footer>
        <FooterLeft>
          © {new Date().getFullYear()} fairy. Privacy ｜ Policy ｜ Terms of Use
        </FooterLeft>
        <FooterRight>
        {social.map(({name, url}) => (
            <a key={name} href={url} target="_blank" rel="noreferrer">
              <div className="social-logo">
                <img className="social-ico" src={`images/footer/${name}.svg`} alt={name}/>
              </div>
            </a>
          ))}
        </FooterRight>
      </Footer>
    </Layout>
  )
}

export default Menu
