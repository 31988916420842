import React from 'react'
import { TokenPairImage, ImageProps } from 'fairyswap-uikit'
import { mainnetTokens, testnetTokens } from 'config/constants/tokens'

const CakeVaultTokenPairImage: React.FC<Omit<ImageProps, 'src'>> = (props) => {
  // const primaryTokenSrc = `/images/tokens/${mainnettokens.fariy.address}.svg`
  const primaryTokenSrc = `/images/tokens/${testnetTokens.fairy.address}.svg`

  return <TokenPairImage primarySrc={primaryTokenSrc} secondarySrc="/images/tokens/autorenew.svg" {...props} />
}

export default CakeVaultTokenPairImage
