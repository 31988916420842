import { ethers } from "ethers";

export async function getProviderInfo(): Promise<[ ethers.providers.Web3Provider, ethers.Signer, string ]> {
  let provider;
  if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  } else if ((window as any).web3) {
    provider = (window as any).currentProvider;
  } else {
    return [ null, null, null ]
  }
  try {
    const list = await provider.listAccounts();
    if (list.length === 0) {
      return [ provider, null, null ];
    }
    
  } catch (e) {
    return [ provider, null, null ];
  }
  const signer = provider.getSigner();
  const account = await signer.getAddress();
  return [ provider, signer, account ];
}