import { Token } from '@fairy-swap-v2/sdk'
import tokens from 'config/constants/tokens'

const { 
  /* bondly, safemoon */
  _
} = tokens

interface WarningTokenList {
  [key: string]: Token
}

const SwapWarningTokens = <WarningTokenList>{
  
}

export default SwapWarningTokens
