const theme2 = {
  color: '#000000',
  accent: '#723CB5',
  sectionTitle: '#000000',
  sectionDesc: '#0000009A',
  
  bg: 'transparent',
  bgDesc: '#28292D',
  bgDescOpac: 0.7,
  
  cardBg: '#FFFFFF',
  cardDesc: '#0000009A',

  buttonText: '#FFFFFF',

  inputBorder: '#EDEDED',

};

export function isMobile(theme) {
  const mediaQueryMobile: string = theme?.devicesQueries?.mobile;
  if (!mediaQueryMobile) { return false; }
  const widthLimit = parseInt(mediaQueryMobile.split('max-width: ')[1].split('px')[0]);
  return window.innerWidth <= widthLimit;
}

export default theme2;
