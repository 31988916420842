import { Currency, ETHER, Token } from '@fairy-swap-v2/sdk'

export function currencyId(currency: Currency): string {
  // if (currency === ETHER) return 'FRA'
  if (currency === ETHER) return '0x0000000000000000000000000000000000001000'
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export default currencyId
