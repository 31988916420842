export default {
  masterChef: {
    2152: '0x072d7Aad4D57d4adBcb6E2264cD9780b59c52b76',
    2153: '0xA4606025191b5EdE06d1e0A3609b8f386e9Bd908'
  },
  sousChef: {
    525: '0xf36Ec0D791d93e114BDC9D3Dfa2a962B1f054B48',
    56: '0xf36Ec0D791d93e114BDC9D3Dfa2a962B1f054B48',
  },
  lotteryV2: {
    2152: '0x5790c3534F30437641541a0FA04C992799602998',
    2153: '0x5790c3534F30437641541a0FA04C992799602998',
  },
  multiCall: {
    2152: '0x0d4cA03a6fc1abd6E57343a86C252a3e17CEa948',
    2153: '0x0d4cA03a6fc1abd6E57343a86C252a3e17CEa948'
  },
  pancakeProfile: {
    2152: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2153: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    525: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    525: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c4523e149',
    525: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    525: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A29523e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    525: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    525: '0xC787F45B833721ED3aC46E99b703B3E1E01abb523',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    525: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    2152: '0xfC0283b1e8801eCEf8ec1622101e0671C27E5a9B',
    2153: '0xfC0283b1e8801eCEf8ec1622101e0671C27E5a9B',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    525: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    525: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    525: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    525: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    525: '0x382cB4523110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    525: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    525: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  Oracle: {
    2152: '0xD947a4d165D7bE793C551247208eCf22b21fcbfe',
    2153: '0xcCC94d78b01D94330F25f7B8e827ef24249132DE'
  },
  IDO:{
    2152: '0x012d6fe6bFA29ab29d090A57003C51DAb1E761A6',
    2153: '0xcC1b76eE99eaA5a941e1C00Ff0Ca8627ab347DAF'
  },
  TradeIn:{
    2152: '0xe48DacbCa1d0A13d0B528F81dd181726A8E9E804',
    2153: '0x63084c5aEF8a253B9e6b156e6F2ABf177994906e'
  },
  AirDrop: {
    2152: '0x41EFAc678B414eDc5313dd1EADf078943012f14A',
    2153: '0xc7e90e63A2CF63783cA18B2EDEe7EE9Ec25a4B7C'
  },
  FNS: {
    2152: '0x9bCffe5Bb2e9D2048468A541FB1eb4069c59Fc57',
    2153: '0xe77B7DDc441B5a695d2D16020bfd5c0b0cE3aC7C'
  }
}