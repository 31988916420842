import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.fairy,
    earningToken: serializedTokens.fairy,
    contractAddress: {
      525: '0x36ABBa64A05566e35Ad02A30fA55cD43aef96722',
      56: '0x4E487E8cf4008F568c8DfA0B63ED6e1AEaAA8BAa',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  // {
  //   sousId: 221,
  //   stakingToken: serializedtokens.fariy,
  //   earningToken: serializedTokens.nft,
  //   contractAddress: {
  //     97: '',
  //     56: '0x8d018823d13c56d62ffb795151a9e629c21e047b',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '173727',
  // },
  // {
  //   sousId: 220,
  //   stakingToken: serializedtokens.fariy,
  //   earningToken: serializedTokens.chess,
  //   contractAddress: {
  //     97: '',
  //     56: '0x4D1Ec426d0d7fb6bF344Dd372d0502EDD71c8d88',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '0.0861',
  // },
]

export default pools
